import { nonnull } from "./assert";
import { LonaWebComponent } from "./component";
import { Constants } from "./constants";
import { $$ } from "./fastdom";
import { dev, err } from "./log";
import { Point, Vector } from "./point";
import { NumRange } from "./range";

export interface CSSCustomProperties {
  [key: `--${string}`]: Option<string>;
}

export interface CSSStyleWithVariables
  extends Partial<CSSStyleDeclaration>,
    CSSCustomProperties {}

export const getAttributeNumber = (
  $e: HTMLElement,
  attributeName: string,
  defaultValue: number | null = null
): number | null => {
  const val = $e.getAttribute(attributeName);
  if (!val) return defaultValue ?? null;
  const numVal = parseInt(val);
  if (isNaN(numVal)) return defaultValue ?? null;
  return numVal;
};

export const getAttributeNumberNonnull = (
  $e: HTMLElement,
  attributeName: string,
  defaultValue: number | null = null
): number => {
  return nonnull(getAttributeNumber($e, attributeName, defaultValue));
};

export const toggleAttributeValue = <T>(
  $e: HTMLElement,
  key: string,
  value: Option<T>
) => {
  value ? $e.setAttribute(key, String(value)) : $e.removeAttribute(key);
};

export const getPropertyValueDefault = (
  $element: HTMLElement,
  key: string,
  defaultValue: string
) => {
  const value = $element.style.getPropertyValue(key);
  return value !== "" ? value : defaultValue;
};

export const createScrollboxObserver = (
  $topEdge: HTMLElement,
  $bottomEdge: HTMLElement,
  $target: HTMLElement,
  $root: HTMLElement
): IntersectionObserver => {
  const observer = new IntersectionObserver(
    ([entry]) => {
      const intersect = entry.intersectionRatio !== 1;
      if (entry.target == $topEdge) {
        $target.toggleAttribute("shadow-top", intersect);
      } else if (entry.target == $bottomEdge) {
        $target.toggleAttribute("shadow-bottom", intersect);
      }
    },
    {
      root: $root,
    }
  );
  observer.observe($topEdge);
  observer.observe($bottomEdge);
  return observer;
};

export namespace DomUtils {
  export function removeFromDom(
    $e: Option<HTMLElement | Element>,
    recycle: boolean = true
  ) {
    if (!recycle) {
      $e?.parentElement?.removeChild($e);
      return;
    }
    if ($e instanceof LonaWebComponent) {
      $e.recycle();
      return;
    }
    $e?.parentElement?.removeChild($e);
  }

  function getMeasureName(): string {
    if ($$.isFlushing()) {
      return `[dom-utils/clear-children]`;
    }
    const err = new Error();
    return `[dom-utils/clear-children] ${err.stack?.split("\n")[3].trim()}`;
  }

  export function recycleSync(elements: Element[]) {
    for (const $child of elements) {
      if ($child instanceof LonaWebComponent) {
        $child.recycle();
      }
    }
  }

  export function clearChildren($parent: HTMLElement): Element[] {
    if ($parent.children.length == 0) return [];
    const $children = [...$parent.children];
    performance.mark(`[dom-utils/clear-children] start`);
    $parent.innerHTML = "";
    performance.mark(`[dom-utils/clear-children] end`);
    performance.measure(
      getMeasureName(),
      `[dom-utils/clear-children] start`,
      `[dom-utils/clear-children] end`
    );
    return $children;
  }

  export function clearChildrenOld(
    $parent: HTMLElement,
    recycle: boolean = true
  ) {
    if ($parent.children.length == 0) return;
    const $children = [...$parent.children];

    performance.mark(`[dom-utils/clear-children] start`);
    $parent.innerHTML = "";
    performance.mark(`[dom-utils/clear-children] end`);
    performance.measure(
      getMeasureName(),
      `[dom-utils/clear-children] start`,
      `[dom-utils/clear-children] end`
    );
    if (!recycle) return;
    $$.defer(() => {
      for (const $child of $children) {
        if ($child instanceof LonaWebComponent) {
          $child.recycle();
        }
      }
    });
  }

  export function toggleInteractable($e: HTMLElement, force: boolean) {
    $e.style.userSelect = force ? "all" : "none";
    $e.style.pointerEvents = force ? "all" : "none";
  }

  export const setEndOfContenteditable = ($e: HTMLElement) => {
    if (!document.createRange) {
      return;
    }

    //Firefox, Chrome, Opera, Safari, IE 9+
    const range = document.createRange(); //Create a range (a range is a like the selection but invisible)
    range.selectNodeContents($e); //Select the entire contents of the element with the range
    range.collapse(false); //collapse the range to the end point. false means collapse to end rather than the start
    const selection = window.getSelection() as Selection; //get the selection object (allows you to change selection)
    selection.removeAllRanges(); //remove any selections already made
    selection.addRange(range); //make the range you have just created the visible selection
  };

  export function appendTemplate(
    $e: HTMLElement | DocumentFragment,
    t: HTMLTemplateElement
  ): HTMLElement {
    $e.appendChild(t.content.cloneNode(true));
    return $e.lastElementChild as HTMLElement;
  }

  export function hasAncestor(
    $e: HTMLElement,
    $ancestor: HTMLElement
  ): boolean {
    // dbg($e, $ancestor, $ancestor.childElementCount);
    let node: Option<Node> = $e;
    while (node) {
      if (node === $ancestor) {
        return true;
      } else if (node instanceof ShadowRoot) {
        node = node.host;
      } else {
        node = node.parentNode;
      }
    }
    return node === $ancestor;
  }

  export function visitAncestors($e: HTMLElement, visit: ($e: Node) => void) {
    let node: Option<Node> = $e;
    while (node) {
      visit(node);
      if (node instanceof ShadowRoot) {
        node = node.host;
      } else {
        node = node.parentNode;
      }
    }
  }

  export function flipAnimationWithRect(
    $e: HTMLElement,
    before: DOMRect,
    after: DOMRect,
    durationMs: number = 300,
    offset: Option<Vector>
  ) {
    const delta = {
      x: before.left - after.left + (offset?.x ?? 0),
      y: before.top - after.top + (offset?.y ?? 0),
    };
    // dev("--", delta);
    $e.animate(
      [
        {
          transformOrigin: "top left",
          transform: `translate(${delta.x}px, ${delta.y}px)`,
        },
        {
          transformOrigin: "top left",
          transform: "none",
        },
      ],
      {
        duration: durationMs,
        easing: "ease",
        fill: "both",
      }
    );
  }

  function flipAnimationRaw(
    $es: HTMLElement[],
    bind: Option<EmptyFunction>,
    durationMs: number = 300,
    offset: Option<Vector> = null
  ) {
    const before = $es.map(($e) => $e.getBoundingClientRect());
    bind &&
      $$.mutate(() => {
        bind();
      });
    $$.defer(() => {
      const after = $es.map(($e) => $e.getBoundingClientRect());
      $es.map(($e, i) =>
        DomUtils.flipAnimationWithRect(
          $e,
          before[i],
          after[i],
          durationMs,
          offset
        )
      );
    });
  }

  export function flipAnimation(
    $es: HTMLElement[],
    bind: Option<EmptyFunction> = null,
    options: Option<
      Optional<{
        shouldMeasure: boolean;
        durationMs: number;
        offset: Vector;
      }>
    > = null
  ) {
    options?.shouldMeasure
      ? $$.measure(() =>
          flipAnimationRaw($es, bind, options.durationMs ?? 300, options.offset)
        )
      : flipAnimationRaw(
          $es,
          bind,
          options?.durationMs ?? 300,
          options?.offset
        );
  }

  export function registerEditableNumberElement(
    $e: HTMLElement,
    onValidNumber: (n: Option<number>) => void,
    bounds: NumRange = {
      start: Number.MIN_SAFE_INTEGER,
      end: Number.MAX_SAFE_INTEGER,
    }
  ) {
    registerKeyListeners($e, {
      onTextChangeDEPRECATED: (t) => {
        if (t == null || t == "") {
          onValidNumber(null);
          return;
        }
        $e.toggleAttribute("invalid", !/^[0-9]*$/.test(t));
        const num = parseInt(t ?? "");
        const invalid = $e.toggleAttribute(
          "invalid",
          isNaN(num) || num < bounds.start || num > bounds.end
        );
        if (invalid) {
          return;
        }
        onValidNumber(num);
      },
      onEnterKey: (t) => {},
    });
  }

  export function registerKeyListeners(
    $e: HTMLElement,
    options: {
      onTextChangeDEPRECATED?: Option<(t: Option<string>) => void>;
      onArrowKeyHorizontal?: Option<(key: "ArrowLeft" | "ArrowRight") => void>;
      onArrowKeyVertical?: Option<(key: "ArrowDown" | "ArrowUp") => void>;
      onEnterKey?: Option<(t: Option<string>) => void>;
    }
  ) {
    $e.onkeydown = (e) => {
      if (e.key == "Enter") e.preventDefault();
      else if (
        (e.key == "ArrowLeft" || e.key == "ArrowRight") &&
        options.onArrowKeyHorizontal
      ) {
        options.onArrowKeyHorizontal(e.key);
        e.preventDefault();
      } else if (
        (e.key == "ArrowUp" || e.key == "ArrowDown") &&
        options.onArrowKeyVertical
      ) {
        options.onArrowKeyVertical(e.key);
        e.preventDefault();
      } else if (e.key == "Escape") {
        $e.blur();
      } else if (options.onTextChangeDEPRECATED) {
        e.stopPropagation();
      }
    };
    $e.onkeyup = (e) => {
      if (e.key == "Enter") {
        options.onEnterKey && options.onEnterKey($e.textContent);
        $e.blur();
        e.preventDefault();
      } else if (e.key == "ArrowLeft" || e.key == "ArrowRight") {
        if (options.onArrowKeyHorizontal) {
          e.preventDefault();
        }
      } else if (e.key == "ArrowDown" || e.key == "ArrowUp") {
        if (options.onArrowKeyVertical) {
          e.preventDefault();
        }
      } else {
        options.onTextChangeDEPRECATED &&
          options.onTextChangeDEPRECATED($e.textContent?.trim());
      }
    };
  }

  export function hotkeyIdentifier(e: KeyboardEvent): Option<string> {
    if (e.key == "Shift" || e.key == "Ctrl" || e.key == "Meta") return null;
    return (
      (e.shiftKey ? "shift-" : "") +
      (e.ctrlKey ? "ctrl-" : "") +
      (e.metaKey ? "cmd-" : "") +
      e.key
    );
  }

  export async function importScript(url: string): Promise<void> {
    return new Promise((resolve, _reject) => {
      const script = document.createElement("script");
      script.setAttribute("src", url);
      script.onload = () => resolve();
      document.head.appendChild(script);
    });
  }

  export function importSvgs(svgs: string[]): string {
    return /* xml */ `
      <svg style="display: none" version="2.0">
        <defs>
          ${svgs.join(" ")}
        </defs>
      </svg>
    `;
  }

  export function setSelected(
    $els: NodeListOf<HTMLElement>,
    isSelected: ($e: HTMLElement) => boolean,
    attribute: string = "selected"
  ) {
    $els.forEach(($e) => $e.toggleAttribute(attribute, isSelected($e)));
  }

  export function assignAttributes<T extends HTMLElement>(
    $e: T,
    attributes: { [k: string]: Option<string> | boolean }
  ): T {
    Object.entries(attributes).forEach(([key, value]) => {
      if (typeof value == "boolean") {
        $e.toggleAttribute(key, value);
        return;
      }
      if (value == null) {
        $e.removeAttribute(key);
      } else {
        $e.setAttribute(key, value);
      }
    });
    return $e;
  }

  export function assignStyles<T extends HTMLElement>(
    $e: T,
    styles: CSSStyleWithVariables
  ): T {
    Object.entries(styles).forEach(([key, value]) => {
      if (key.startsWith("--")) {
        if (value == null) {
          $e.style.removeProperty(key);
        } else {
          $e.style.setProperty(key, value);
        }
      } else {
        $e.style[key] = value;
      }
    });
    return $e;
  }

  export function slotChildren($e: HTMLSlotElement): HTMLElement[] {
    return $e
      .assignedNodes()
      .filter((node) => node instanceof HTMLElement)
      .map((e) => e as HTMLElement);
  }

  export function findOverflowParent($e: HTMLElement) {
    let $parent = $e.parentElement;
    while ($parent) {
      dev("parent: ", $parent);
      if ($parent.style.overflow != null && $parent.style.overflow != null) {
        err("ui")("found overflow parent", $parent, $parent.style.overflow);
      }
      $parent = $parent.parentElement;
    }
  }

  window["findOverflowParent"] = findOverflowParent;

  export function hydrate<T extends HTMLElement>($from: HTMLElement): T {
    customElements.upgrade($from);
    return $from as T;
  }
}
