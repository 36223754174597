export * from "./result";
export * from "./range";

export * from "./units/mod";

export * from "./time";
export * from "./naive-time";
export * from "./naive-date";
export * from "./naive-datetime";
export * from "./datetime";
export * from "./date-region";
export * from "./timezone";
export * from "./timezone-region";

export * from "./rrule/rrule";

import { DateTime } from "./datetime";
import { NaiveDate } from "./naive-date";
import { NaiveDateTime } from "./naive-datetime";
import { NaiveTime } from "./naive-time";
import { Result } from "./result";

export function naivedate(year: number, mth1: number, day1: number): NaiveDate {
  return Result.unwrap(NaiveDate.fromYmd1(year, mth1, day1));
}

export function naivedatetime(
  year: number = 0,
  mth1: number = 1,
  day1: number = 1,
  hrs: number = 0,
  mins: number = 0,
  secs: number = 0
): NaiveDateTime {
  return new NaiveDateTime(
    naivedate(year, mth1, day1),
    NaiveTime.fromHms({
      hrs,
      mins,
      secs,
    })
  );
}

export const rfc3339 = DateTime.fromRfc3339;
