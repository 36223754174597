let _canAdoptStylesheetCached: Option<boolean>;
function _canAdoptStylesheet(): boolean {
  if (_canAdoptStylesheetCached != null) return _canAdoptStylesheetCached;
  try {
    new CSSStyleSheet();
  } catch (err) {
    return (_canAdoptStylesheetCached = false);
  }
  return (_canAdoptStylesheetCached = true);
}

export const css = (s: TemplateStringsArray | string): Style.Compat => {
  if (_canAdoptStylesheet()) {
    const sheet = new CSSStyleSheet();
    sheet.replaceSync(`${s}`);
    return sheet;
  }

  return Style.make(s.toString());
};

export namespace Style {
  export type Compat = HTMLStyleElement | CSSStyleSheet;

  export const canAdoptStylesheet = _canAdoptStylesheet;

  export const $borderBoxStyle = css`
    * {
      box-sizing: border-box;
    }
    :host {
      box-sizing: border-box;
    }
  `;

  export function make(style: string): HTMLStyleElement {
    const $style = document.createElement("style");
    $style.textContent = style;
    return $style;
  }

  const cache: Map<Compat, HTMLStyleElement> = new Map();
  export function makeWith(s: Compat): HTMLStyleElement {
    if (s instanceof HTMLStyleElement) {
      return s.cloneNode(true) as HTMLStyleElement;
    }
    const $cached = cache.get(s);
    if ($cached) {
      return $cached.cloneNode(true) as HTMLStyleElement;
    }
    const style = [...s.cssRules].map((r) => r.cssText).join("\n");
    const $template = make(style);
    cache.set(s, $template);
    return $template.cloneNode(true) as HTMLStyleElement;
  }
}
