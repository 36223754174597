import {
  GenericRange,
  NaiveDate,
  NaiveTime,
  Result,
  Time,
  TimeUnit,
  TimeUnitLikeOpt,
  Weekday,
  YearMonthDay,
} from "./mod";
import { DateTimeUnitLikeOpt, NaiveDateTime } from "./naive-datetime";
import { LogicalTimezone, Utc, Local, FixedOffset } from "./timezone";
import { DaysSinceEpoch, Ms, MsSinceEpoch } from "./units/units";

const RFC3339_REGEX =
  /^(\d{4})-(\d{2})-(\d{2})(?:T(\d{2}):(\d{2}):(\d{2})(\.\d+)?(Z|[+-]\d{2}:\d{2})?)?$/;

export class DateTime<TzType extends string = any> {
  static utc = Utc;
  static local = Local;

  constructor(
    public readonly ndt: NaiveDateTime,
    public readonly tz: LogicalTimezone<TzType>
  ) {}

  get mse(): MsSinceEpoch {
    return this.toUtc().ndt.mse;
  }

  get dse(): DaysSinceEpoch {
    return this.toUtc().ndt.dse;
  }

  get date(): NaiveDate {
    return this.ndt.date;
  }

  get time(): NaiveTime {
    return this.ndt.time;
  }

  get dow(): Weekday {
    return this.ndt.date.dayOfWeek;
  }

  /**
   * Compare
   */
  durationSince(before: DateTime<any>): TimeUnit {
    const mseDiff = this.mse - before.mse;
    return TimeUnit.fromMs(mseDiff as Ms);
  }

  isBefore(other: DateTime<any>): boolean {
    return this.compare(other) < 0;
  }

  compare(other: DateTime<any>): number {
    return this.compareSame(other.toTz(this.tz));
  }

  compareSame(other: DateTime<TzType>): number {
    const dseDiff = this.ndt.date.dse - other.ndt.date.dse;
    if (dseDiff != 0) return dseDiff;

    const msDiff = this.ndt.time.asMs - other.ndt.time.asMs;
    // console.log({ msDiff }, this.dt.timeOfDay.asMs, other.dt.timeOfDay.asMs);
    // console.log(this.rfc3339(), other.rfc3339());
    if (Math.abs(msDiff) <= 1) {
      return 0;
    }
    return msDiff;
  }

  /**
   * Conversion
   */
  withTime(time: NaiveTime = NaiveTime.ZERO): DateTime<TzType> {
    return new DateTime(new NaiveDateTime(this.date, time), this.tz);
  }

  asTz<Tz extends string>(offset: LogicalTimezone<Tz>): DateTime<Tz> {
    return new DateTime(this.ndt, offset);
  }

  toTz<Tz extends string = any>(tz: LogicalTimezone<Tz>): DateTime<Tz> {
    if (this.tz.info == tz.info) {
      return this as unknown as DateTime<Tz>;
    }
    return new DateTime(
      this.ndt.add(tz.info.offset.sub(this.tz.info.offset)),
      tz
    );
  }

  toUtc(): DateTime<Utc> {
    if (this.tz.id == "utc") {
      return this as DateTime<Utc>;
    }
    return this.toTz(Utc);
  }

  /**
   * Ops
   */
  add(dt: DateTimeUnitLikeOpt): DateTime<TzType> {
    return new DateTime(this.ndt.add(dt), this.tz);
  }

  sub(dt: DateTimeUnitLikeOpt): DateTime<TzType> {
    return new DateTime(this.ndt.sub(dt), this.tz);
  }

  nearest(
    time: TimeUnitLikeOpt,
    op: (n: number) => number = Math.round
  ): DateTime<TzType> {
    return new DateTime(this.ndt.nearest(time, op), this.tz);
  }

  /**
   * String
   */
  rfc3339(): string {
    const sb: string[] = ["", "T", "", ""];
    sb[0] = this.ndt.date.rfc3339();
    sb[2] = this.ndt.time.rfc3339();
    sb[3] = this.tz.rfc3339;
    return sb.join("");
  }

  toString(): string {
    return this.rfc3339();
  }

  toJSON(): string {
    return this.rfc3339();
  }
}

export namespace DateTime {
  export function fromMse<TzType extends string>(
    mse: MsSinceEpoch,
    tz: LogicalTimezone<TzType>
  ): DateTime<TzType> {
    if (tz.info.offset.asMs == 0) {
      return new DateTime(NaiveDateTime.fromMse(mse), tz);
    }
    return new DateTime(
      NaiveDateTime.fromMse((mse + tz.info.offset.asMs) as MsSinceEpoch),
      tz
    );
  }

  export function fromDse<TzType extends string>(
    dse: DaysSinceEpoch,
    tz: LogicalTimezone<TzType>
  ): DateTime<TzType> {
    return DateTime.fromMse((dse * Time.MS_PER_DAY) as MsSinceEpoch, tz);
  }

  export function fromRfc3339(rfc3339: string): Result<DateTime<FixedOffset>> {
    const match = rfc3339.match(RFC3339_REGEX);
    if (!match) return Error("regex-fail");

    const [_, year, month, day, hour, minute, second, __, timezone] = match;

    const nd = NaiveDate.fromYmd1Str(year, month, day);
    if (Result.isErr(nd)) return nd as Error;

    const ndMaybeValid = Result.unwrap(nd).check();
    if (Result.isErr(ndMaybeValid)) return ndMaybeValid as Error;

    const ndValid = Result.unwrap(ndMaybeValid);

    const nt = NaiveTime.fromHmsStr(hour, minute, second);
    if (Result.isErr(nt)) return nt as Error;

    const dt = new DateTime(
      new NaiveDateTime(ndValid, Result.unwrap(nt)),
      LogicalTimezone.parse(timezone)
    );

    return dt;
  }

  export class Range<Tz extends string = any> extends GenericRange<
    DateTime<Tz>
  > {
    overlapsWithDay(date: DateTime<Tz>): "start" | "middle" | "end" | "none" {
      if (this.start.date.equals(date.date)) return "start";
      if (this.end.date.equals(date.date)) return "end";
      if (this.contains(date)) return "middle";
      return "none";
    }

    contains(date: DateTime<Tz>): boolean {
      return this.start.mse < date.mse && this.end.mse > date.mse;
    }

    toTz(tz: LogicalTimezone<FixedOffset> = Utc): Range<FixedOffset> {
      return new Range(this.start.toTz(tz), this.end.toTz(tz));
    }
  }
}
