import {
  DateTime,
  DateUnitLike,
  LogicalTimezone,
  Time,
  TimeUnit,
  TimeUnitLike,
  TimeUnitLikeOpt,
  Utc,
} from "./mod";
import { NaiveDate } from "./naive-date";
import { NaiveTime } from "./naive-time";
import { DaysSinceEpoch, MsSinceEpoch } from "./units/units";

export type DateTimeUnitLike = DateUnitLike & TimeUnitLike;
export type DateTimeUnitLikeOpt = Optional<DateTimeUnitLike>;

export class NaiveDateTime {
  constructor(
    public readonly date: NaiveDate,
    public readonly time: NaiveTime = NaiveTime.ZERO
  ) {}

  add(delta: DateTimeUnitLikeOpt): NaiveDateTime {
    const added = this.time.add(delta);
    if (added.days == 0) {
      return new NaiveDateTime(this.date, added);
    }
    const rem = added.sub({ days: added.days });

    // todo: handle wks, months, year

    return new NaiveDateTime(this.date.addDays(added.days).castValid(), rem);
  }

  sub(delta: DateTimeUnitLikeOpt): NaiveDateTime {
    return this.add(TimeUnit.from(delta).mult(-1));
  }

  nearest(
    time: TimeUnitLikeOpt,
    op: (n: number) => number = Math.round
  ): NaiveDateTime {
    return new NaiveDateTime(this.date, this.time.nearest(time, op));
  }

  get mse(): MsSinceEpoch {
    return (this.date.daysSinceEpoch * Time.MS_PER_DAY +
      this.time.asMs) as MsSinceEpoch;
  }

  get dse(): DaysSinceEpoch {
    return this.date.daysSinceEpoch;
  }

  asUtc(): DateTime<Utc> {
    return new DateTime(this, Utc);
  }

  withTz<Tz extends string>(offset: LogicalTimezone<Tz>): DateTime<Tz> {
    return new DateTime(this, offset);
  }

  withTzConvert<Tz extends string>(offset: LogicalTimezone<Tz>): DateTime<Tz> {
    // todo: convert time
    return new DateTime(this, offset);
  }
}

export namespace NaiveDateTime {
  export function fromMse(mse: MsSinceEpoch): NaiveDateTime {
    const { div, rem } = NaiveTime.divByDay(mse);
    return new NaiveDateTime(
      NaiveDate.fromDse(div as DaysSinceEpoch),
      new NaiveTime(rem)
    );
  }

  export namespace Formatter {
    export function render(s: NaiveDateTime): string {
      return `${s.date.month.name} ${s.date.day1}, ${NaiveTime.Formatter.render(
        s.time
      )}`;
    }

    // todo:
    export function renderDateDiff(s: NaiveDateTime, o: NaiveDateTime): string {
      return "1 day ago";
    }
  }
}
