/*
 Decorators
 ===============================================================================
*/
export function component(config: { name: string }): ClassDecorator {
  return (constructor: Function) => {
    constructor["componentName"] = config.name;
    window.customElements.define(
      config.name,
      constructor as CustomElementConstructor
    );
    window[constructor.name] = constructor;
  };
}
