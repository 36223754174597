export interface DateUnitLike {
  readonly yrs: number;
  readonly mths: number;
  readonly days: number;
  readonly wks: number;
}
export type DateUnitLikeOpt = Optional<DateUnitLike>;
export type DateUnitType = "years" | "months" | "days" | "weeks";

export class DateUnit<Unit extends DateUnitType = any>
  implements DateUnitLikeOpt
{
  readonly value: number;
  readonly type: Unit;

  get yrs(): Option<number> {
    return this.type == "years" ? this.value : null;
  }
  get mths(): Option<number> {
    return this.type == "months" ? this.value : null;
  }
  get days(): Option<number> {
    return this.type == "days" ? this.value : null;
  }
  get wks(): Option<number> {
    return this.type == "weeks" ? this.value : null;
  }

  constructor(value: number, type: Unit) {
    this.value = value;
    this.type = type;
  }

  static years(yrs: number): DateUnit<"years"> {
    return new DateUnit(yrs, "years");
  }

  static months(mths: number): DateUnit<"months"> {
    return new DateUnit(mths, "months");
  }

  static days(days: number): DateUnit<"days"> {
    return new DateUnit(days, "days");
  }

  static weeks(wks: number): DateUnit<"weeks"> {
    return new DateUnit(wks, "weeks");
  }

  mult(scalar: number): DateUnit<Unit> {
    return new DateUnit(this.value * scalar, this.type);
  }

  equals(other: DateUnit): boolean {
    return this.value == other.value && this.type == other.type;
  }
}
