import { range, GenericRangeLike } from "../range";

import { Month } from "./month";
import { Month1, DayOfMonth1, DayOfYear1 } from "./units";
import { DateUnit } from "./date-unit";
import { Weekday } from "./weekday";
import { Year } from "./year";
import { YearMonthDay } from "./year-month-day";
import { YearMonth, Ym1Like } from "./year-month";
import { PartialDateInlined } from "./partial-date";

export type YearMonthDayFilter = (
  partialdate: PartialDateInlined
) => Generator<PartialDateInlined>;

export namespace YearMonthDayFilter {
  type Filter = YearMonthDayFilter;

  export function identity(): Filter {
    return ([nd]) => _identity(nd);
  }

  function* _identity(
    nd: YearMonthDay.MaybeValid
  ): Generator<PartialDateInlined> {
    yield [nd, "days"];
  }

  export function compose(filters: Filter[]): Filter {
    return (partialdate: PartialDateInlined) => _compose(partialdate, filters);
  }

  function* _compose(partialdate: PartialDateInlined, filters: Filter[]) {
    const generators: Generator<PartialDateInlined>[] = [];

    let curr: PartialDateInlined = partialdate;
    for (const [idx, f] of filters.entries()) {
      const gen = f(curr);
      const next = gen.next();
      if (next.done) break;
      generators.push(gen);
      curr = next.value;
      if (idx == filters.length - 1) yield curr;
    }

    while (true) {
      if (generators.length == filters.length) {
        const leaf = generators.pop();
        if (!leaf) return;
        for (const value of leaf) yield value;
      }

      const top = generators.pop();
      if (!top) break;

      const next = top.next();
      if (next.done) continue;

      curr = next.value;
      generators.push(top);

      const gen = filters[generators.length]!(curr);
      generators.push(gen);
    }
  }

  export function byWeekday(dayOfWeeks: Weekday[]): Filter {
    const dow1: Option<boolean>[] = [];
    for (const dow of dayOfWeeks) {
      dow1[dow.dow] = true;
    }
    return (partialdate: PartialDateInlined) => _byWeekday(partialdate, dow1);
  }

  function* _byWeekday(
    [cand, unit]: PartialDateInlined,
    dow1: Option<boolean>[]
  ): Generator<PartialDateInlined> {
    switch (unit) {
      case "years":
        // TODO: we can use a better generator for this instead of checking
        // each day
        for (const day of range(Year.length(cand.yr))) {
          const nd = cand.addDays(day - 1);
          if (dow1[nd.dayOfWeek.dow]) yield [nd, "days"];
        }
        break;
      case "months":
        for (const day of YearMonth.dom(cand)) {
          const nd = YearMonthDay.fromYmd1Unchecked(cand.yr, cand.mth, day);
          if (dow1[nd.dayOfWeek.dow]) yield [nd, "days"];
        }
        break;
      case "weeks":
        for (let i = 0; i < 7; ++i) {
          const day = cand.addDays(i);
          if (dow1[day.dayOfWeek.dow]) yield [day, "days"];
        }
        break;
      case "days":
        if (dow1[cand.dayOfWeek.dow]) yield [cand, "days"];
        break;
    }
  }

  export function byMonthOfYear(months: Month[]): Filter {
    return (partialdate) =>
      _byMonthOfYear(partialdate, new Set(months.map((mth) => mth.mth1)));
  }

  function* _byMonthOfYear(
    [partialdate, unit]: PartialDateInlined,
    mths: Set<Month1>
  ): Generator<PartialDateInlined> {
    switch (unit) {
      case "years":
        const start = YearMonthDay.fromYmd1Exp(
          partialdate.yr,
          1,
          partialdate.day
        );
        for (const mth of start.succ(DateUnit.months(1), 12)) {
          if (mths.has(mth.mth)) yield [mth, "months"];
        }
        break;
      case "months":
        if (mths.has(partialdate.mth)) yield [partialdate, "months"];
        break;
      case "weeks":
        if (mths.has(partialdate.mth)) yield [partialdate, "weeks"];
        break;
      case "days":
        if (mths.has(partialdate.mth)) yield [partialdate, "days"];
        break;
    }
  }

  export function byDayOfMonth(dayOfMonths: DayOfMonth1[] | number[]): Filter {
    const domsPos = dayOfMonths.filter((doy) => doy >= 0);
    domsPos.sort((a, b) => a - b);
    const domsNeg = dayOfMonths.filter((doy) => doy < 0);
    domsNeg.sort((a, b) => b - a);

    // todo: memo this
    const makeDoms = (ym1: Ym1Like): DayOfMonth1[] => {
      const monthLen = YearMonth.daysInMonth(ym1);
      const doms = [
        ...domsPos,
        ...domsNeg.map((dom) => monthLen + dom + 1),
      ] as DayOfMonth1[];
      doms.sort();
      return doms;
    };
    return (partialdate: PartialDateInlined) =>
      _byDayOfMonth(partialdate, makeDoms);
  }

  function* _byDayOfMonth(
    [cand, unit]: PartialDateInlined,
    makeDoms: (ym1: Ym1Like) => DayOfMonth1[]
  ): Generator<PartialDateInlined> {
    switch (unit) {
      case "years":
        const start = YearMonthDay.fromYmd1Exp(cand.yr, 1, 1);
        for (const mth of start.succ(DateUnit.months(1), 12)) {
          for (const day of makeDoms(mth)) {
            yield [
              YearMonthDay.fromYmd1Unchecked(mth.yr, mth.mth, day),
              "days",
            ];
          }
        }
        break;
      case "months":
        for (const day of makeDoms(cand)) {
          yield [
            YearMonthDay.fromYmd1Unchecked(cand.yr, cand.mth, day),
            "days",
          ];
        }
        break;
      case "weeks":
        // todo: pretty inefficient - mem/hashmap?
        for (let i = 0; i < 7; ++i) {
          const date = cand.add({ days: i - 1 });
          const doms = makeDoms(date);
          if (doms.includes(date.day)) yield [date, "days"];
        }
        break;
      case "days":
        if (makeDoms(cand).includes(cand.day)) yield [cand, "days"];
        break;
    }
  }

  export function byDayOfYear(dayOfYears: DayOfYear1[] | number[]): Filter {
    const doysPos = dayOfYears.filter((doy) => doy >= 0);
    const doysNeg = dayOfYears.filter((doy) => doy < 0);

    const doys365 = [...doysPos, ...doysNeg.map((doy) => 365 + doy + 1)];
    doys365.sort();
    const doys366 = [...doysPos, ...doysNeg.map((doy) => 365 + doy + 1)];
    doys366.sort();

    return (partialdate: PartialDateInlined) =>
      _byDayOfYear(partialdate, doys365, doys366);
  }

  function* _byDayOfYear(
    [cand, unit]: PartialDateInlined,
    doys365: number[],
    doys366: number[]
  ): Generator<PartialDateInlined> {
    const yr = cand.yr;
    const doys = Year.isLeapYear(yr) ? doys366 : doys365;
    for (const doy of doys) {
      const date = YearMonthDay.fromYmd1Exp(yr, 1, 1).addDays(doy - 1);
      switch (unit) {
        case "years":
          if (date.yr == cand.yr) yield [date, "days"];
        case "months":
          if (date.mth == cand.mth) yield [date, "days"];
        case "weeks":
        case "days":
          if (date.cmpInvalid(cand) == 0) yield [date, "days"];
      }
    }
  }

  export function byWeekNo(weekno1: number): Filter {
    return (partialdate: PartialDateInlined) => _byWeekNo(partialdate, weekno1);
  }

  function* _byWeekNo(
    [cand, unit]: PartialDateInlined,
    weekno1: number
  ): Generator<PartialDateInlined> {
    const shouldAccept = (nd: YearMonthDay.MaybeValid): boolean => {
      switch (unit) {
        case "years":
          return true;
        case "months":
          return nd.mth == cand.mth;
        case "weeks":
        case "days":
          return nd.mth == cand.mth && nd.day == cand.day;
      }
    };

    if (weekno1 >= 0) {
      const start = Year.isoStart(cand.yr);
      if (weekno1 >= 53) {
        const end = Year.isoStart(cand.yr + 1);
        const numWeeks = Math.floor((end.dse - start.dse) / 7);
        if (numWeeks < weekno1) return;
      }

      const dayRange: GenericRangeLike<number> = {
        start: (weekno1 - 1) * 7,
        end: weekno1 * 7,
      };

      for (let s = dayRange.start; s < dayRange.end; ++s) {
        const d = start.addDays(s);
        if (!shouldAccept(d)) continue;
        yield [d, "days"];
      }
    } else {
      const next = Year.isoStart(cand.yr + 1);
      const dayRange: GenericRangeLike<number> = {
        start: weekno1 * 7,
        end: (weekno1 + 1) * 7,
      };

      for (let s = dayRange.start; s < dayRange.end; ++s) {
        const d = next.addDays(s);
        if (!shouldAccept(d)) continue;
        yield [d, "days"];
      }
    }
  }

  export function byNthWeekday(
    dayOfWeeks: Weekday.Nth[],
    simpleEqForDays: boolean = true
  ): Filter {
    return (partialdate: PartialDateInlined) =>
      _byNthWeekday(partialdate, dayOfWeeks, simpleEqForDays);
  }

  function* _byNthWeekday(
    [cand, unit]: PartialDateInlined,
    dayOfWeeks: Weekday.Nth[],
    simpleEqForDays: boolean = true
  ): Generator<PartialDateInlined> {
    switch (unit) {
      case "years":
        {
          const wrap = Year.length(cand.yr);
          const start = YearMonthDay.fromYmd1Exp(cand.yr, 1, 1);
          const end = YearMonthDay.fromYmd1Exp(cand.yr, 12, 31);
          const days = Weekday.nthForYear(dayOfWeeks, start, end, wrap);

          for (const ndow of days) {
            yield [start.add({ days: ndow }), "days"];
          }
        }
        break;
      case "months":
        {
          const wrap = YearMonth.daysInMonth(cand);
          const start = YearMonthDay.fromYmd1Exp(cand.yr, cand.mth, 1);
          const end = YearMonthDay.fromYmd1Exp(cand.yr, cand.mth, wrap);
          const days = Weekday.nthForYear(dayOfWeeks, start, end, wrap);

          for (const ndow of days) {
            yield [start.add({ days: ndow }), "days"];
          }
        }
        break;
      case "weeks": {
        // todo:
        //
        // if (rrulejs) {
        //   // todo: pretty inefficient - mem/hashmap?
        //   for (let i = 0; i < 7; ++i) {
        //     const date = partialdate.add({ days: i - 1 });
        //     const doms = makeDoms(date);
        //     if (doms.includes(date.day)) yield [date, "days"];
        //   }
        //   // for (const ndow of dayOfWeeks) {
        //   //   if (ndow.weekday == partialdate.dayOfWeek)
        //   //     yield [partialdate, "days"];
        //   // }
        //   return;
        // }
      }
      case "days":
        {
          if (simpleEqForDays) {
            for (const ndow of dayOfWeeks) {
              if (ndow.weekday == cand.dayOfWeek) yield [cand, "days"];
            }
            return;
          }

          // NOTE: represents Nth of every month
          //
          const wrap = YearMonth.daysInMonth(cand);
          const start = YearMonthDay.fromYmd1Exp(cand.yr, cand.mth, 1);
          const end = YearMonthDay.fromYmd1Exp(cand.yr, cand.mth, wrap);
          const days = Weekday.nthForYear(dayOfWeeks, start, end, wrap);

          const ndowDays = days.map((days) => start.add({ days }));
          for (const ndow of ndowDays) {
            if (ndow.cmpInvalid(cand) == 0) yield [ndow, "days"];
          }
        }
        break;
    }
  }
}
