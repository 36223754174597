import { dev, err } from "./log";

export class Fastdom {
  static enabled = true;

  private reads: EmptyFunction[] = [];
  private writes: EmptyFunction[] = [];
  private writesEnd: EmptyFunction[] = [];
  private flushing: boolean = false;
  private scheduled: boolean = false;
  private flushCount = 0;
  private flushType: Option<string>;

  constructor() {
    this.reads = [];
    this.writes = [];
    this.writesEnd = [];
    this.flushing = false;
  }

  runTasks(tasks: EmptyFunction[]) {
    let task: Option<EmptyFunction>;
    while ((task = tasks.shift())) {
      try {
        task();
      } catch (e) {
        setTimeout(() => {
          // err("ui")("fastdom/Error flushing task: ", e);
          throw e;
        });
      }
    }
  }

  measure(fn: EmptyFunction, ctx?: Option<any>) {
    const task = !ctx ? fn : fn.bind(ctx);
    this.reads.push(task);
    this.scheduleFlush();
    return task;
  }

  mutate(fn: EmptyFunction, ctx?: Option<any>) {
    const task = !ctx ? fn : fn.bind(ctx);
    this.writes.push(task);
    this.scheduleFlush();
    return task;
  }

  mutateEnd(fn: EmptyFunction, ctx?: Option<any>) {
    const task = !ctx ? fn : fn.bind(ctx);
    this.writesEnd.push(task);
    this.scheduleFlush();
    return task;
  }

  defer(fn: EmptyFunction) {
    this.mutateEnd(() => {
      setTimeout(() => fn());
    });
  }

  deferMutate(fn: EmptyFunction) {
    const mut = this.mutate.bind(this);
    this.mutateEnd(() => setTimeout(() => mut(fn)));
  }

  clear(task: EmptyFunction) {
    return (
      this.remove(this.reads, task) ||
      this.remove(this.writes, task) ||
      this.remove(this.writesEnd, task)
    );
  }

  isFlushing() {
    return this.flushing;
  }

  raf(fn: EmptyFunction) {
    return window.requestAnimationFrame(fn);
  }

  /**
   * Schedules a new read/write
   * batch if one isn't pending.
   *
   * @private
   */
  private scheduleFlush() {
    if (this.scheduled) return;
    this.scheduled = true;
    this.raf(this.flush.bind(this));
  }

  /**
   * Runs queued `read` and `write` tasks.
   *
   * Errors are caught and thrown by default.
   * If a `.catch` function has been defined
   * it is called instead.
   *
   * @private
   */
  private flush() {
    let writes = this.writes;
    let writesEnd = this.writesEnd;
    let reads = this.reads;
    let error: Option<any>;

    this.flushing = true;

    // 1) Read
    const readId = this.flushCount;
    performance.mark(`[${readId}] read/start`);
    // console.log(`[${readId}] read`);

    // dev("FLUSHING", this.getState());
    // for (const fn of this.reads) {
    //   dev(">> read    ", fn);
    // }
    // for (const fn of this.writes) {
    //   dev(">> write   ", fn);
    // }
    // for (const fn of this.writesEnd) {
    //   dev(">> writeEnd", fn);
    // }

    // 1a) Run
    this.flushType = "reads";
    this.runTasks(reads);

    // 2) Write
    const writeId = this.flushCount;
    performance.mark(`[${writeId}] write/start`);
    performance.measure(
      `[${readId}] read`,
      `[${readId}] read/start`,
      `[${writeId}] write/start`
    );
    // console.log(`[${writeId}] write`);

    // 2a) Run
    this.flushType = "writes";
    this.runTasks(writes);

    // 3) WriteEnd
    const writeEndId = this.flushCount;
    performance.mark(`[${writeEndId}] write-end/start`);
    performance.measure(
      `[${writeEndId}] write`,
      `[${writeId}] write/start`,
      `[${writeEndId}] write-end/start`
    );
    // console.log(`[${writeEndId}] writeEnd`);

    // 3a) Run
    this.flushType = "writes-end";
    this.runTasks(writesEnd);

    performance.mark(`[${writeEndId}] write-end/end`);
    performance.measure(
      `[${writeEndId}] write-end`,
      `[${writeEndId}] write-end/start`,
      `[${writeEndId}] write-end/end`
    );

    this.flushType = null;
    this.flushCount += 1;
    this.flushing = false;
    this.scheduled = false;

    // If the batch errored we may still have tasks queued
    if (reads.length || writes.length || writesEnd.length) this.scheduleFlush();

    if (error) {
      console.error("error running batches", error);
      throw error;
    }
  }

  /**
   * Remove an item from an Array.
   *
   * @param  {Array} array
   * @param  {*} item
   * @return {Boolean}
   */
  remove<T>(array: T[], item: T): boolean {
    const index = array.indexOf(item);
    return !!~index && !!array.splice(index, 1);
  }

  getState(): {
    // flushing: boolean;
    flushCount: number;
    // flushType: Option<string>;
  } {
    return {
      // flushing: this.flushing,
      flushCount: this.flushCount,
      // flushType: this.flushType,
    };
  }
}

export const $$ = (window["$$"] = Fastdom.enabled
  ? new Fastdom()
  : {
      measure: (fn: any) => fn(),
      mutate: (fn: any) => fn(),
      mutateEnd: (fn: any) => fn(),
      defer: (fn: any) => fn(),
      deferMutate: (fn: any) => fn(),
      isFlushing: () => false,
      getState: () => ({ flushCount: 0, flushing: false, flushType: null }),
    });

export function mutate(
  _target: Object,
  propertyKey: string | symbol,
  descriptor: TypedPropertyDescriptor<any>
): TypedPropertyDescriptor<any> {
  const method = descriptor.value!;
  descriptor.value = function (...args: any[]) {
    $$.mutate(() => {
      method.apply(this, args);
    });
  };
  return descriptor;
}

export function measure(
  _target: Object,
  propertyKey: string | symbol,
  descriptor: TypedPropertyDescriptor<any>
): TypedPropertyDescriptor<any> {
  const method = descriptor.value!;
  descriptor.value = function (...args: any[]) {
    $$.measure(() => {
      method.apply(this, args);
    });
  };
  return descriptor;
}
