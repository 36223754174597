import { GenericRange } from "../range";
import { Result } from "../result";
import { Gecko } from "../impl";

import { DateUnit, DateUnitLikeOpt } from "./date-unit";
import { Month } from "./month";
import {
  DayOfMonth,
  DayOfMonth0,
  DayOfMonth1,
  DayOfWeek1,
  DayOfYear1,
  DaysSinceEpoch,
  Month0,
  Month1,
  MonthOfYear,
  MsSinceEpoch,
  Unit,
  WeekOfYear1,
} from "./units";
import { Weekday } from "./weekday";
import { Year } from "./year";

import { Time } from "../time";
import { YearMonth, YmLike } from "./year-month";
import { YearMonthDayFilter } from "./year-month-day-filter";
import { TimeUnit } from "./time-unit";
import { NaiveDateTime } from "../naive-datetime";
import { PartialDate } from "./partial-date";
import { Week } from "./week";
import { NaiveDate } from "../naive-date";

export interface YmdLike<Index> extends YmLike<Index> {
  readonly yr: number;
  readonly mth: MonthOfYear<Index>;
  readonly day: DayOfMonth<Index>;
}

export type YmdPartialLike<Index> = Optional<YmdLike<Index>>;
export type Ymd1Like = YmdLike<1>;
export type Ymd0Like = YmdLike<0>;

export type Ymd1LikeOpt = Optional<Ymd1Like>;

export namespace Ymd1Like {
  export const fromDse = Gecko.makeYmd;

  export function daysSinceEpoch(ymd: Ymd1Like): DaysSinceEpoch {
    return (Year.dseFromYear(ymd.yr) +
      YearMonth.doyForMonthStart(ymd) +
      ymd.day -
      1) as DaysSinceEpoch;
  }
  export const dse = daysSinceEpoch;

  export function dayOfWeek(ymd: Ymd1Like): DayOfWeek1 {
    const weekday = Weekday.fromDse(Ymd1Like.dse(ymd));
    return (weekday != 0 ? weekday : 7) as DayOfWeek1;
  }

  export function dayOfYear(ymd: Ymd1Like): DayOfYear1 {
    return (Month.MONTH_START_OF_YEAR[Year.isLeapYear(ymd.yr) ? 1 : 0][
      ymd.mth - 1
    ] + ymd.day) as DayOfYear1;
  }
}

export type CachedYmdInfo = {
  dayOfWeek: Weekday;
  dse: DaysSinceEpoch;
  isValid: boolean;
  validationError: Error;
};

class _YearMonthDay implements Ymd1Like {
  constructor(
    public readonly ymd1: Ymd1Like,
    public readonly cached: Optional<CachedYmdInfo> = {}
  ) {}

  #validationError(): Option<Error> {
    if (this.month1 < 1 || this.month1 > 12) return Error("month/bounds");
    if (!YearMonth.isDayValid(this, this.day)) return Error("day/bounds");
    return null;
  }

  check(): Result<YearMonthDay.Valid> {
    if (this.cached.isValid != null && !this.cached.isValid) {
      return this.cached.validationError!;
    }
    const err = this.#validationError();
    if (err) {
      this.cached.isValid = false;
      return (this.cached.validationError = err);
    } else {
      this.cached.isValid = true;
      return this as unknown as YearMonthDay.Valid;
    }
  }

  assertValid(): YearMonthDay.Valid {
    return Result.unwrap(this.check());
  }

  isValid(): boolean {
    return Result.isOk(this.check());
  }

  castValid(): YearMonthDay.Valid {
    return this as unknown as YearMonthDay.Valid;
  }

  castMaybeInValid(): YearMonthDay.MaybeValid {
    return this as unknown as YearMonthDay.MaybeValid;
  }

  /**
   * [Interface] Ymd1
   */
  get yr(): number {
    return this.ymd1.yr;
  }
  get mth(): Month1 {
    return this.ymd1.mth;
  }
  get day(): DayOfMonth1 {
    return this.ymd1.day;
  }

  /**
   * [Getters] Month
   */
  get month(): Month {
    return Month.MONTHS0[this.month0];
  }

  get month0(): Month0 {
    return (this.ymd1.mth - 1) as Month0;
  }

  get month1(): Month1 {
    return this.ymd1.mth;
  }

  /**
   * [Getters] Week-Day
   */
  get dayOfWeek(): Weekday {
    if (this.cached.dayOfWeek) return this.cached.dayOfWeek;
    return (this.cached.dayOfWeek = Weekday.DOW1[Ymd1Like.dayOfWeek(this) - 1]);
  }

  /**
   * [Getters] Month-Day
   */
  get day0(): DayOfMonth0 {
    return (this.ymd1.day - 1) as DayOfMonth0;
  }

  get day1(): DayOfMonth1 {
    return this.ymd1.day;
  }

  get dayOfMonth(): DayOfMonth1 {
    return this.ymd1.day;
  }

  /**
   * [Getters] Year-Day
   */
  get dayOfYear(): DayOfYear1 {
    return Ymd1Like.dayOfYear(this);
  }

  get daysSinceEpoch(): DaysSinceEpoch {
    return this.dse;
  }

  get dse(): DaysSinceEpoch {
    if (this.cached.dse != null) return this.cached.dse;
    return (this.cached.dse = Ymd1Like.daysSinceEpoch(this.ymd1));
  }

  get mse(): MsSinceEpoch {
    return (this.dse * Time.MS_PER_DAY) as MsSinceEpoch;
  }

  get wno(): WeekOfYear1 {
    // --- 1) Find the Monday of this year's ISO week #1 ---
    //     (the week containing Jan 4)
    const jan4 = YearMonthDay.fromYmd1Exp(this.yr, 1, 4);
    const jan4dowRaw = jan4.dayOfWeek.dow; // e.g. Sunday=1, Monday=2, ...
    const jan4dowIso = toIsoDow(jan4dowRaw); // convert so Monday=1..Sunday=7

    // Step back to Monday
    const thisYearFirstIsoMon = jan4.addDays(-(jan4dowIso - 1));

    // --- 2) Preliminary week number ---
    const diffDays = this.differenceInDays(thisYearFirstIsoMon);
    let weekNumber = Math.floor(diffDays / 7) + 1;

    // --- 3) If <1, spill into previous year's last ISO week ---
    if (weekNumber < 1) {
      const prevYear = this.yr - 1;
      const prevJan4 = YearMonthDay.fromYmd1Exp(prevYear, 1, 4);
      const pj4dowIso = toIsoDow(prevJan4.dayOfWeek.dow);
      const prevYearFirstIsoMon = prevJan4.addDays(-(pj4dowIso - 1));
      const diffDaysPrev = this.differenceInDays(prevYearFirstIsoMon);
      return (Math.floor(diffDaysPrev / 7) + 1) as WeekOfYear1;
    }

    // --- 4) Possibly spill into next year's first ISO week ---
    //     e.g. 2024-12-30 is the Monday of 2025's week #1
    const nextYear = this.yr + 1;
    const nextJan4 = YearMonthDay.fromYmd1Exp(nextYear, 1, 4);
    const nj4dowIso = toIsoDow(nextJan4.dayOfWeek.dow);
    const nextYearFirstIsoMon = nextJan4.addDays(-(nj4dowIso - 1));
    const diffDaysNext = this.differenceInDays(nextYearFirstIsoMon);
    if (diffDaysNext >= 0) {
      // "This date" is on or after next year's ISO week #1 Monday
      return 1 as WeekOfYear1;
    }

    // --- 5) Otherwise, we remain in the current year's computed week ---
    return weekNumber as WeekOfYear1;
  }

  get wse(): Unit<"days", 1, "epoch"> {
    return Week.weeksSinceEpoch(this.yr, this.wno) as Unit<"days", 1, "epoch">;
  }

  /**
   * Ops
   */
  addYears(yrs: number): YearMonthDay.MaybeValid {
    if (yrs == 0) return this.castMaybeInValid();
    return YearMonthDay.fromYmd1Unchecked(this.yr + yrs, this.month1, this.day);
  }

  addMonths(mths: number): YearMonthDay.MaybeValid {
    if (mths == 0) return this.castMaybeInValid();
    const { yr, mth } = YearMonth.add(this, { mth: mths as Option<Month0> });
    return YearMonthDay.fromYmd1Unchecked(yr, mth, this.day);
  }

  // static addDays<Valid>(
  //   nd: YearMonthDay<Valid>,
  //   days: number
  // ): YearMonthDay<Valid> {
  //   /**
  //    * If the original date is
  //    *    ...invalid, it will become MaybeValid
  //    *    ...valid, it will stay valid
  //    *    ...maybeInvalid, it will stay maybeInvalid
  //    *
  //    * If the caller wants to promote an invalid day to an valid day by adding
  //    * days, they must call .check(). This function (and the typesystem) will
  //    * not be able to do it automatically.
  //    */
  //   if (days == 0) return nd;
  //   return YearMonthDay.fromDse(
  //     (nd.dse + days) as DaysSinceEpoch
  //   ) as YearMonthDay<Valid>;
  // }

  addDaysOpt(days: number): YearMonthDay.MaybeValid {
    if (days == 0) return this.castMaybeInValid();
    let d = this.day + days;
    let yr = this.ymd1.yr;
    let mth = this.ymd1.mth;
    let daysInMonth = YearMonth.daysInMonth({ yr, mth });
    while (d > daysInMonth) {
      d -= daysInMonth;
      mth = (mth + 1) as Month1;
      if (mth > 12) {
        mth = 1 as Month1;
        yr += 1;
      }
      daysInMonth = YearMonth.daysInMonth({ yr, mth });
    }
    while (d <= 0) {
      mth = (mth - 1) as Month1;
      if (mth <= 0) {
        mth = 12 as Month1;
        yr -= 1;
      }
      daysInMonth = YearMonth.daysInMonth({ yr, mth });
      d += daysInMonth;
    }
    return YearMonthDay.fromYmd1Unchecked(yr, mth, d);
  }

  addDays(days: number): YearMonthDay.Valid {
    return this.addDaysOpt(days).assertValid();
  }

  addWeeks(weeks: number): YearMonthDay.MaybeValid {
    return this.addDays(weeks * 7);
  }

  addOpt(delta: DateUnitLikeOpt): YearMonthDay.MaybeValid {
    let dt = this.castMaybeInValid();
    if (delta.yrs) {
      dt = dt.addYears(delta.yrs);
    }
    if (delta.mths) {
      dt = dt.addMonths(delta.mths);
    }
    if (delta.wks) {
      dt = dt.addWeeks(delta.wks);
    }
    if (delta.days) {
      dt = dt.addDaysOpt(delta.days);
    }
    return dt;
  }

  add(delta: DateUnitLikeOpt): YearMonthDay.Valid {
    return this.addOpt(delta).assertValid();
  }

  differenceInDays(other: YearMonthDay): number {
    return this.dse - other.dse;
  }

  differenceInMonths(other: YearMonthDay.MaybeValid): number {
    return (this.yr - other.yr) * 12 + (this.mth - this.mth);
  }

  toStartOfMonth(iso: boolean = false): YearMonthDay {
    return iso
      ? YearMonth.isoStart(this)
      : YearMonthDay.fromYmd1Exp(this.yr, this.mth, 1);
  }

  toStartOfWeek(): YearMonthDay {
    return this.addDays(-this.dayOfWeek.dow + 1).assertValid();
  }

  mthIsoStart(): YearMonthDay {
    return YearMonth.isoStart(this);
  }

  mthStart(): YearMonthDay {
    return YearMonthDay.fromYmd1Exp(this.yr, this.mth, 1);
  }

  withTime(timeOfDay: TimeUnit = TimeUnit.ZERO): NaiveDateTime {
    return new NaiveDateTime(this.assertValid(), timeOfDay);
  }

  /**
   * Eq/Cmp
   */
  cmpInvalid(other: YearMonthDay.MaybeValid): number {
    if (this.yr != other.yr) return this.yr - other.yr;
    if (this.mth != other.mth) return this.mth - other.mth;
    return this.day - other.day;
  }

  isBefore(other: YearMonthDay.MaybeValid): boolean {
    return this.cmpInvalid(other) <= 0;
  }

  equals(other: YearMonthDay.MaybeValid): boolean {
    return this.cmpInvalid(other) == 0;
  }

  /**
   * For a given startdate: 2024-11-25
   *
   * Generates:
   *   Years: 2024, 2025, 2026, ...
   *   Months: 2024/11, 2024/12, 2025/1, ...
   *   Days: 2024/11/25, 2024/11/26, ...
   *   Weeks: 2024/11/25, 2024/12/2, ...
   *
   */
  succ(
    step: DateUnit,
    recurLimit: number = 10_000
  ): Generator<YearMonthDay.MaybeValid> {
    return YearMonthDay.ndsucc(this.castMaybeInValid(), step, recurLimit);
  }

  range(
    step: DateUnit = DateUnit.days(1),
    options: Option<
      Optional<{
        end: YearMonthDay; // inclusive
        limit: number;
        recurLimit: number;
        filter: YearMonthDay.Filter | YearMonthDay.Filter[];
      }>
    > = null
  ): Generator<YearMonthDay> {
    const recurLimit = options?.recurLimit ?? 10_000;
    const start = this.castMaybeInValid();
    const filterr = options?.filter ?? YearMonthDay.Filter.identity();
    const filter = Array.isArray(filterr)
      ? YearMonthDay.Filter.compose(filterr)
      : filterr;
    return YearMonthDay.ndrange({
      start,
      step,
      filter,
      recurLimit,
      end: options?.end,
      limit: options?.limit,
    });
  }

  /**
   * toString
   */
  toString(): string {
    return this.rfc3339();
  }

  toJSON(): string {
    return this.rfc3339();
  }

  rfc3339(): string {
    return [
      this.yr,
      String(this.month1).padStart(2, "0"),
      String(this.day1).padStart(2, "0"),
    ].join("-");
  }
}

export type YearMonthDay<isValid = "valid"> = _YearMonthDay & {
  readonly __is_valid: isValid;
};

export namespace YearMonthDay {
  export type Valid = YearMonthDay<"valid">;
  export type MaybeValid = YearMonthDay<"valid" | "invalid">;
  export type Invalid = YearMonthDay<"invalid">;

  /*
   * [Constructors]
   */
  export function fromYmd1Unchecked(
    year: number,
    month1: number,
    day1: number
  ): YearMonthDay.MaybeValid {
    return new _YearMonthDay({
      yr: year,
      mth: month1 as Month1,
      day: day1 as DayOfMonth1,
    }) as YearMonthDay.MaybeValid;
  }

  export function fromYmd1Exp(
    year: number,
    month1: number,
    day1: number
  ): YearMonthDay {
    return Result.unwrap(
      YearMonthDay.fromYmd1Unchecked(year, month1, day1).check()
    );
  }

  export function fromYmd1(
    year: number,
    month1: number,
    day1: number
  ): Result<YearMonthDay> {
    return YearMonthDay.fromYmd1Unchecked(year, month1, day1).check();
  }

  export function fromRfc3339(s: string): Result<YearMonthDay> {
    const [yr, mth, day] = s.split("-");
    return YearMonthDay.fromYmd1Str(yr, mth, day);
  }

  export function parse(s: string): YearMonthDay {
    return Result.unwrap(YearMonthDay.fromRfc3339(s));
  }

  export function fromYmd1Str(
    year: string,
    month1: string,
    day1: string
  ): Result<YearMonthDay> {
    const yr = parseInt(year);
    if (isNaN(yr)) return Error(`parse/yr: ${year}`);

    const mth = parseInt(month1);
    if (isNaN(mth)) return Error(`parse/mth: ${month1}`);

    const day = parseInt(day1);
    if (isNaN(day)) return Error(`parse/day: ${day}`);

    return YearMonthDay.fromYmd1(yr, mth, day);
  }

  export function fromYmd0(
    year: number,
    month0: number,
    day0: number
  ): YearMonthDay.MaybeValid {
    return new _YearMonthDay({
      yr: year,
      mth: (month0 + 1) as Month1,
      day: (day0 + 1) as DayOfMonth1,
    }) as YearMonthDay.MaybeValid;
  }

  export function fromDse(dse: DaysSinceEpoch): YearMonthDay {
    return new _YearMonthDay(Ymd1Like.fromDse(dse)) as YearMonthDay;
  }

  export function fromMse(mse: MsSinceEpoch): YearMonthDay {
    return YearMonthDay.fromDse(
      Math.floor(mse * Time.MS_PER_DAY_INV) as DaysSinceEpoch
    );
  }

  export let _today: Option<Valid>;
  export function today(): Valid {
    if (_today) return _today;
    const date = new Date();
    return (_today = YearMonthDay.fromYmd1Exp(
      date.getFullYear(),
      date.getMonth() + 1,
      date.getDate()
    ));
  }

  /**
   * Filters
   */
  export function* ndsucc(
    curr: YearMonthDay.MaybeValid,
    step: DateUnit,
    recurLimit: number
  ) {
    for (let i = 0; i < recurLimit; ++i) {
      const next = curr.add(step);
      yield curr;
      curr = next;
    }
  }

  export function* ndrange({
    start,
    step,
    recurLimit,
    filter,
    end,
    limit,
  }: {
    start: YearMonthDay.MaybeValid;
    step: DateUnit;
    recurLimit: number;
    filter: YearMonthDay.Filter;
    end: Option<YearMonthDay>;
    limit: Option<number>;
  }) {
    let recurCnt = 0;
    let cnt = 0;
    for (const i of YearMonthDay.ndsucc(start, step, recurLimit)) {
      for (const [ndu, _] of filter([i, step.type])) {
        const nd = Result.opt(ndu.check());
        if (!nd) continue;
        if (nd.cmpInvalid(start) < 0) continue;
        if (end && nd.cmpInvalid(end) > 0) continue;
        yield nd;
        cnt += 1;
        recurCnt += 1;
        if (limit && cnt >= limit) return;
        if (recurCnt >= recurLimit) return;
      }
    }
  }

  export import Filter = YearMonthDayFilter;

  export import Partial = PartialDate;

  export class PartialRange extends GenericRange<Partial> {
    toRange(): NaiveDate.Range {
      return new NaiveDate.Range(this.start.start, this.end.end);
    }
  }

  export namespace Formatter {
    export function render(s: YearMonthDay): string {
      return `${s.month.name} ${s.day1}`;
    }
  }

  export class Span {
    private readonly cache: Optional<{
      resolved: Range;
    }> = {};

    constructor(
      public readonly d: YearMonthDay,
      public readonly span: DateUnit
    ) {}

    next(): Span {
      return new Span(this.d.add(this.span), this.span);
    }

    get index(): number {
      return this.d.dse;
    }

    resolve(): Range {
      if (this.cache.resolved) return this.cache.resolved;
      return (this.cache.resolved = new Range(this.d, this.d.add(this.span)));
    }
  }

  export class SpanRange extends GenericRange<Span> {}

  export class Range extends GenericRange<YearMonthDay> {
    private readonly cached: Optional<{
      rangeExcl: Readonly<YearMonthDay[]>;
    }> = {};

    containsExclusiveEnd(d: YearMonthDay): boolean {
      const n = d.dse;
      return n >= this.start.dse && n <= this.end.dse;
    }

    contains(d: YearMonthDay) {
      const n = d.dse;
      return n >= this.start.dse && n < this.end.dse;
    }

    numDays(): number {
      return this.end.dse - this.start.dse;
    }

    range(): Readonly<YearMonthDay[]> {
      if (this.cached.rangeExcl) return this.cached.rangeExcl;
      return (this.cached.rangeExcl = Object.freeze(
        Array.from({ length: this.numDays() }, (_, i) => this.start.addDays(i))
      ));
    }
  }
}

/** Convert from "Sunday=1..Saturday=7" => "Monday=1..Sunday=7". */
function toIsoDow(rawDow: number): number {
  // Sunday=1 => ISO=7
  // Monday=2 => ISO=1
  // Tuesday=3 => ISO=2
  // ...
  // Saturday=7 => ISO=6
  return ((rawDow + 5) % 7) + 1;
}
