import { Epoch } from "./epoch";
import {
  DayOfWeek,
  DayOfWeek1,
  DayOfYear1,
  DaysSinceEpoch,
  MsSinceEpoch,
} from "./units";
import { YearMonthDay } from "./year-month-day";
// import { YearMonthDay } from "./year-month-day";

export class Weekday {
  static WEEKDAY_SHORT = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  static WEEKDAY_FULL = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  static WEEKDAY_SHORT2 = ["su", "mo", "tu", "we", "th", "fr", "sa"];

  //
  // 21.4.1.6 Week Day
  //
  static fromDse(dse: DaysSinceEpoch): DayOfWeek1 {
    let result = (dse + 5) % 7;
    if (result < 0) result += 7;
    return result as DayOfWeek1;
  }

  static fromMse(mse: MsSinceEpoch): DayOfWeek1 {
    return Weekday.fromDse(Epoch.mseToDse(mse));
  }

  static shortName(dow: DayOfWeek1): string {
    return Weekday.WEEKDAY_SHORT[dow - 1];
  }

  static fullName(dow: DayOfWeek1): string {
    return Weekday.WEEKDAY_FULL[dow - 1];
  }

  readonly dow: DayOfWeek1;
  readonly label: string;

  constructor(dow: DayOfWeek1) {
    this.dow = dow;
    this.label = Weekday.shortName(this.dow);
  }

  get isoDow(): DayOfWeek<0, "mon"> {
    return ((this.dow - 2 + 7) % 7) as DayOfWeek<0, "mon">;
  }

  get shortname(): string {
    return Weekday.shortName(this.dow);
  }

  nth(n: number): Weekday.Nth {
    return {
      weekday: this,
      n,
    };
  }

  get initialCaps(): string {
    return this.label[0];
  }

  static nthForYear(
    ndows: Weekday.Nth[],
    start: YearMonthDay,
    end: YearMonthDay,
    endDay: number
  ): DayOfYear1[] {
    const startWkday = start.dayOfWeek;
    const endWkday = end.dayOfWeek;

    const ndowsByDay = ndows.map((dow) => {
      if (dow.n > 0) {
        let offset = dow.weekday.dow - startWkday.dow;
        if (offset < 0) offset += 7;
        offset += (dow.n - 1) * 7;
        return offset;
      } else {
        let offset = dow.weekday.dow - endWkday.dow;
        if (offset > 0) offset -= 7;
        offset += (dow.n + 1) * 7;
        return endDay + offset - 1;
      }
    });

    ndowsByDay.sort((a, b) => a - b);
    return ndowsByDay as DayOfYear1[];
  }

  /**
   * NOTE: deno is very strict about ordering
   */
  static SUN = new Weekday(1 as DayOfWeek1);
  static MON = new Weekday(2 as DayOfWeek1);
  static TUE = new Weekday(3 as DayOfWeek1);
  static WED = new Weekday(4 as DayOfWeek1);
  static THU = new Weekday(5 as DayOfWeek1);
  static FRI = new Weekday(6 as DayOfWeek1);
  static SAT = new Weekday(7 as DayOfWeek1);

  static DOW1 = [
    Weekday.SUN,
    Weekday.MON,
    Weekday.TUE,
    Weekday.WED,
    Weekday.THU,
    Weekday.FRI,
    Weekday.SAT,
  ];

  static parse(s: Option<string>): Option<Weekday> {
    if (!s) return null;
    const key = s.slice(0, 2).toLowerCase();
    const idx = Weekday.WEEKDAY_SHORT2.findIndex((s) => s == key);
    return idx != -1 ? Weekday.DOW1[idx] : null;
  }

  static toString2cap(weekday: Weekday): string {
    return weekday.label.slice(0, 2).toUpperCase();
  }

  toString(): string {
    return this.label;
  }
}

export namespace Weekday {
  export type Nth = {
    weekday: Weekday;
    n: number;
  };
}
