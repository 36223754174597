export type LogCategory = "network" | "ui" | "unknown" | "init" | "nav" | "sw";
export type LogFunction = (...data: any[]) => void;

export namespace Log {
  let logLevel = 0;

  const startMs = new Date().getTime();
  let lastLogMs = startMs;

  const categoryToBgColor: { [k in LogCategory]: string } = {
    network: "#cbe9c8",
    init: "#999cff",
    ui: "#999cff",
    nav: "#dbacff",
    unknown: "#d1d1d1",
    sw: "#fe887c",
  };

  const layout = "padding-left:4px;padding-right:4px;border-radius:4px";
  export const prefix = (
    category: Option<LogCategory>,
    id: string,
    fgColor: string,
    bgColor: string,
    marginLeft: number = -logLevel * 14
  ) => [
    `%c${id}%c${String(category ?? "unknown").padStart(
      8,
      " "
    )}%c${timestamp()}%c    ›`,
    `color:${fgColor};background-color:${bgColor};${layout};margin-left:${marginLeft}px;margin-right:4px;`,
    `color:black;background-color:${
      categoryToBgColor[category ?? "unknown"]
    };${layout};margin-right:4px;`,
    "",
    `margin-right: 10px;`,
  ];

  function msToTimeString(ms: number) {
    if (ms > 30_000) {
      return `${Math.trunc(ms / 1000)}.${Math.trunc(
        (ms % 1000) / 100
      )}s`.padStart(10, " ");
    } else if (ms > 1_000) {
      return `${Math.trunc(ms / 1000)}.${Math.trunc(
        (ms % 1000) / 10
      )}s`.padStart(10, " ");
    } else {
      return `${ms}ms`.padStart(10, " ");
    }
  }

  export function timestamp(): string {
    const prevLastLogMs = lastLogMs;
    lastLogMs = new Date().getTime();
    const deltaMs = lastLogMs - prevLastLogMs;
    let sb = msToTimeString(lastLogMs - startMs);
    sb += deltaMs > 0 ? msToTimeString(deltaMs) : "          ";
    return sb;
  }

  export function getStartMs(): number {
    return startMs;
  }

  export function trace(
    _target: Object,
    propertyKey: string | symbol,
    descriptor: TypedPropertyDescriptor<any>
  ): TypedPropertyDescriptor<any> {
    const method = descriptor.value!;
    descriptor.value = function (...args: any[]) {
      pushLogLevel();
      console.group(
        `%c ${String(this.constructor.name)}.${String(propertyKey)}`,
        `font-weight:400;position:absolute;display:block;color:gray;`
        // `margin-left:260px;font-weight:400;position:absolute;display:block;color:gray;`,
      );
      method.apply(this, args);
      console.groupEnd();
      popLogLevel();
    };
    return descriptor;
  }

  export function pushLogLevel() {
    logLevel += 1;
  }
  export function popLogLevel() {
    logLevel -= 1;
  }

  export const _console: Console =
    typeof window !== "undefined" ? window.console : console;
}

export const info: (c?: Option<LogCategory>) => LogFunction = (c) =>
  console.log.bind(Log._console, ...Log.prefix(c, "i", "black", "#999cfe"));
export const warn: (c?: Option<LogCategory>) => LogFunction = (c) =>
  console.log.bind(Log._console, ...Log.prefix(c, "w", "black", "#fbd55a"));
export const err: (c?: Option<LogCategory>) => LogFunction = (c) =>
  console.log.bind(Log._console, ...Log.prefix(c, "e", "black", "#fe887c"));
export const dbgRaw: (c?: Option<LogCategory>) => LogFunction = (c) =>
  console.log.bind(Log._console, ...Log.prefix(c, "~", "white", "black"));
export const todo = err;
export const dbg: LogFunction = dbgRaw("ui").bind(Log._console);
export const dev = dbg;
export const dd = dbg;
export const sw = dbgRaw("sw").bind(Log._console);
export let ldev = (key: string, o: any) => {};
