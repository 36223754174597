import { LonaWebComponent, template } from "../../component";
import { component } from "../../component-decorators";
import { css } from "../../component-styles";

@component({
  name: "std-footer",
})
export class Footer extends LonaWebComponent {
  static $styles = [
    css`
      #root {
        margin-block: 40px;
      }

      a {
        margin-right: 20px;
      }

      a:hover {
        text-decoration: underline;
      }

      .arw-right {
        margin-left: 2px;
        margin-bottom: 1px;
        height: 7px;
        transform: rotate(-45deg);
      }
    `,
  ];
  static $html: Option<HTMLTemplateElement> = template`
    <std-row id=root>
      <a>
        <p>
          Terms
          <svg class=arw-right viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.125 5C12.125 4.81667 12.0417 4.64167 11.8917 4.5L7.59166 0.208332C7.44166 0.0666616 7.275 0 7.1 0C6.73334 0 6.45 0.274995 6.45 0.65C6.45 0.825002 6.50833 1 6.63333 1.11667L7.78333 2.30833L10.6 4.85L10.75 4.48334L8.53333 4.325H0.666671C0.275009 4.325 0 4.60834 0 5C0 5.39167 0.275009 5.67501 0.666671 5.67501H8.53333L10.75 5.51666L10.6 5.15833L7.78333 7.69167L6.63333 8.88333C6.50833 9 6.45 9.175 6.45 9.35C6.45 9.725 6.73334 10 7.1 10C7.275 10 7.44166 9.93333 7.59166 9.79167L11.8917 5.5C12.0417 5.35833 12.125 5.18333 12.125 5Z" fill="#161616"/>
          </svg>
        </p>
      </a>
      <a>
        <p>
          Privacy
          <svg class=arw-right viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.125 5C12.125 4.81667 12.0417 4.64167 11.8917 4.5L7.59166 0.208332C7.44166 0.0666616 7.275 0 7.1 0C6.73334 0 6.45 0.274995 6.45 0.65C6.45 0.825002 6.50833 1 6.63333 1.11667L7.78333 2.30833L10.6 4.85L10.75 4.48334L8.53333 4.325H0.666671C0.275009 4.325 0 4.60834 0 5C0 5.39167 0.275009 5.67501 0.666671 5.67501H8.53333L10.75 5.51666L10.6 5.15833L7.78333 7.69167L6.63333 8.88333C6.50833 9 6.45 9.175 6.45 9.35C6.45 9.725 6.73334 10 7.1 10C7.275 10 7.44166 9.93333 7.59166 9.79167L11.8917 5.5C12.0417 5.35833 12.125 5.18333 12.125 5Z" fill="#161616"/>
          </svg>
        </p>
      </a>
    </std-row>
  `;
}
