import { nonnull } from "./assert";
import { Constants } from "./constants";
import { DomUtils } from "./dom";
import { DomEventUtils } from "./dom-event-utils";
import { $ } from "./dom-selectors";
import { dev } from "./log";
import { Point } from "./point";
import { Session } from "./session";

export class GestureManager {
  private gestureLockHandle: Option<string>;
  private pressed: boolean = false;
  private $pressedElement: Option<HTMLElement>;
  private $capturedElement: Option<HTMLElement>;

  private gestureStartX: Option<number>;
  private gestureStartY: Option<number>;
  private gestureDragProgressX: Option<number>;
  private gestureDragProgressY: Option<number>;
  private documentPointerDragMove: Option<
    (e: { progressX: number; progressY: number }) => void
  >;
  private documentPointerDragUp: Option<
    (e: { progressX: number; progressY: number }) => void
  >;

  private onClickOutsideCallbacks: Map<
    HTMLElement,
    ($pointerUpElement: Option<HTMLElement>) => void
  > = new Map();

  constructor() {
    document.onpointerup = (e) => {
      const $target = e.target instanceof HTMLElement ? e.target : null;

      if (!this.pressed) {
        this.emitOnClickOutside($target, $target);
        this.$pressedElement = null;
        return;
      }
      this.pressed = false;
      this.gestureLockHandle = null;
      document.body.releasePointerCapture(e.pointerId);
      // document.body.style.removeProperty("user-select");
      if (!this.documentPointerDragUp) {
        this.emitOnClickOutside($target, $target);
        this.$pressedElement = null;
        return;
      }

      if (
        !this.gestureDragProgressX ||
        Math.abs(this.gestureDragProgressX) < 3 ||
        !this.gestureDragProgressY ||
        Math.abs(this.gestureDragProgressY) < 3
      ) {
        this.emitOnClickOutside($target, $target);
      }

      e.stopPropagation();
      this.documentPointerDragUp({
        progressX: nonnull(this.gestureStartX) - e.pageX,
        progressY: nonnull(this.gestureStartY) - e.pageY,
      });
      this.gestureDragProgressX = null;
      this.$pressedElement = null;
    };

    document.onpointermove = (e) => {
      if (!this.pressed) {
        return;
      }
      this.gestureDragProgressX = nonnull(this.gestureStartX) - e.pageX;
      this.gestureDragProgressY = nonnull(this.gestureStartY) - e.pageY;
      if (this.documentPointerDragMove) {
        e.stopPropagation();
        this.documentPointerDragMove({
          progressX: this.gestureDragProgressX,
          progressY: this.gestureDragProgressY,
        });
      }
    };
  }

  disablePointers($e: HTMLElement) {
    $e.onpointerdown = DomEventUtils.STOP_PROPAGATION;
    $e.onpointerup = DomEventUtils.STOP_PROPAGATION;
  }

  setPointerCapture($e: HTMLElement) {
    this.$capturedElement = $e;
  }

  clearPointerCapture($e: HTMLElement) {
    if (this.$capturedElement == $e) {
      this.$capturedElement = null;
    }
  }

  private pointerEvents = ((self) => ({
    oncontextmenu: (
      e: MouseEvent,
      cb: (client: Point, offset: Point) => void
    ): boolean => {
      if (Session.isWindows) {
        cb(client(e), offset(e));
      }
      e.stopPropagation();
      e.preventDefault();
      return false;
    },
    onpointerdown: (
      e: PointerEvent,
      $e: HTMLElement,
      onPointerDown: Option<(offset: Point) => void>,
      onContextMenu: Option<(client: Point, offset: Point) => void>
    ) => {
      self.$pressedElement = $e;
      e.stopPropagation();
      e.preventDefault();
      if (e.button == 0) {
        onPointerDown && onPointerDown(offset(e));
      } else {
        onContextMenu && onContextMenu(client(e), offset(e));
      }
    },
    ondoubleclick: (e: MouseEvent, cb: EmptyFunction) => {
      e.stopPropagation();
      if (e.button == 0) cb();
      self.$pressedElement = null;
    },
    onpointerup: (
      e: PointerEvent,
      $e: HTMLElement,
      canHandleCapturedElement: Option<($e: HTMLElement) => boolean>,
      onClick: Option<
        ($currentlyCapturedElement: Option<HTMLElement>, offset: Point) => void
      >,
      onContextMenu: Option<(client: Point, offset: Point) => void>
    ) => {
      if (self.$capturedElement) {
        if (canHandleCapturedElement && !canHandleCapturedElement($e)) {
          const cb = self.onClickOutsideCallbacks.get(self.$capturedElement);
          cb && cb($e);
          self.clearOnClickOutside($e);
          return;
        }
      }
      self.emitOnClickOutside(this.$pressedElement, $e);
      if (e.button == 0) {
        e.stopPropagation();
        onClick && onClick(this.$capturedElement, offset(e));
      }
      if (e.button == 2 && !Session.isWindows && onContextMenu) {
        onContextMenu(client(e), offset(e));
        e.stopPropagation();
        e.preventDefault();
      }
      self.$pressedElement = null;
    },
  }))(this);

  addPointerEvent(
    $e: HTMLElement,
    {
      onPointerDown,
      onClick,
      onContextMenu,
      onDoubleClick,
      canHandleCapturedElement,
    }: GestureManager.PointerEvents
  ) {
    /**
     * Note: we separate out the functions here to reduce memory.
     * addPointerEvent is called *frequently* and generating a massive callback
     * function per instance is heafty.
     */
    $e.oncontextmenu = onContextMenu
      ? (e) => this.pointerEvents.oncontextmenu(e, onContextMenu)
      : null;
    $e.onpointerdown = (e) =>
      this.pointerEvents.onpointerdown(e, $e, onPointerDown, onContextMenu);
    $e.ondblclick = onDoubleClick
      ? (e) => this.pointerEvents.ondoubleclick(e, onDoubleClick)
      : null;
    $e.onpointerup = (e) =>
      this.pointerEvents.onpointerup(
        e,
        $e,
        canHandleCapturedElement,
        onClick,
        onContextMenu
      );
  }

  setOnClickOutside($e: HTMLElement, cb: ($e: Option<HTMLElement>) => void) {
    this.onClickOutsideCallbacks.set($e, cb);
  }

  clearOnClickOutside($e: HTMLElement) {
    this.onClickOutsideCallbacks.delete($e);
  }

  toggleCleanUpOnClickOutside(
    $e: HTMLElement,
    cb: ($e: Option<HTMLElement>) => void,
    force: boolean = true
  ) {
    if (force) {
      GESTURE_MANAGER.setOnClickOutside($e, ($pointerUpElement) => {
        cb($pointerUpElement);
        GESTURE_MANAGER.clearOnClickOutside($e);
      });
    } else {
      GESTURE_MANAGER.clearOnClickOutside($e);
    }
  }

  cleanupCallback($e: HTMLElement) {
    const maybeCb = this.onClickOutsideCallbacks.get($e);
    if (!maybeCb) return;
    maybeCb(undefined);
  }

  private emitOnClickOutside(
    $pointerDownElement: Option<HTMLElement>,
    $target: Option<HTMLElement>
  ) {
    if ($pointerDownElement != $target) {
      return;
    }
    for (const [$e, cb] of this.onClickOutsideCallbacks.entries()) {
      if ($e == $target) {
        continue;
      }
      if (
        $pointerDownElement != null &&
        DomUtils.hasAncestor($pointerDownElement, $e)
      ) {
        continue;
      }
      cb($pointerDownElement ?? undefined);
    }
  }

  addDragGesture(
    $e: HTMLElement,
    id: string,
    cbs: {
      onPointerDown?: (e: PointerEvent) => void;
      onPointerMove: (e: { progressX: number; progressY: number }) => void;
      onPointerUp?: Option<
        (e: { progressX: number; progressY: number }) => void
      >;
    }
  ) {
    $e.onpointerdown = (e) => {
      if (this.gestureLockHandle != null) {
        return;
      }
      if (e.button !== 0) {
        return;
      }
      e.stopPropagation();
      this.gestureStartX = e.pageX;
      this.gestureStartY = e.pageY;
      cbs.onPointerDown && cbs.onPointerDown(e);

      document.body.setPointerCapture(e.pointerId);

      this.gestureLockHandle = id;
      this.pressed = true;

      this.documentPointerDragMove = cbs.onPointerMove;
      this.documentPointerDragUp = cbs.onPointerUp;

      document.body.style.userSelect = "none";
    };
  }
}

export const GESTURE_MANAGER = new GestureManager();

export namespace GestureManager {
  export type PointerEvents = Optional<{
    onPointerDown: (offset: Point) => void;
    onClick: (
      $currentlyCapturedElement: Option<HTMLElement>,
      offset: Point
    ) => void;
    onContextMenu: (client: Point, offset: Point) => void;
    onDoubleClick: EmptyFunction;
    canHandleCapturedElement: ($e: HTMLElement) => boolean;
  }>;
}

export function offset(p: PointerEvent | MouseEvent): Point {
  return {
    x: p.offsetX,
    y: p.offsetY,
  };
}

export function client(p: PointerEvent | MouseEvent): Point {
  return {
    x: p.clientX,
    y: p.clientY,
  };
}
