import { DateTime, NaiveDate, Result } from "./mod";
import { Epoch } from "./units/epoch";
import { TimeUnit } from "./units/time-unit";
import { MsSinceEpoch } from "./units/units";

export type Tzname = string; // "America/New_York", "UTC"
export type Tzabbr = string; // "UTC", "PST", "PDT"

export interface TimezoneInfo {
  tzabbr?: Option<Tzabbr>;
  tzname?: Option<Tzname>;
  offset: TimeUnit;
}

export namespace TimezoneInfo {
  let _local: Option<TimezoneInfo>;
  export function local(): TimezoneInfo {
    if (_local) return _local;
    return (_local = {
      tzabbr: localTzabbr(),
      tzname: localTzName(),
      offset: TimeUnit.fromHms({
        // NOTE: Date().getTzOffset is reversed from the RFC3339 implementation
        // ie.
        //   For tzname = "America/New_York"
        //     js::Date -> getTimezoneOffset() == "+5:00"
        //     rfc3339  ->                     == "-5:00"
        //
        mins: -new Date().getTimezoneOffset(),
      }),
    });
  }

  function localTzName(): Tzname {
    return Intl.DateTimeFormat("en-US").resolvedOptions().timeZone;
  }

  function localTzabbr(): Option<Tzabbr> {
    const formatter = new Intl.DateTimeFormat("en-US", {
      timeZoneName: "short",
    });
    const parts = formatter.formatToParts(new Date());
    const timeZone = parts.find((part) => part.type === "timeZoneName");
    return timeZone ? timeZone.value : null;
  }
}

export interface LogicalTimezone<Id extends string = any> {
  id: Id;
  info: TimezoneInfo;
  rfc3339: string;
}

export namespace LogicalTimezone {
  const namelessTimezones: Map<
    Tzabbr,
    LogicalTimezone<FixedOffset>
  > = new Map();

  // "Z", "-07:00", "-08:00", etc
  export function parse(tz: string): LogicalTimezone<FixedOffset> {
    if (tz == "Z" || tz == "z") return Utc;

    const cached = namelessTimezones.get(tz);
    if (cached) return cached;

    let offsetStr = tz;
    let sign = 1;

    const plus = tz.startsWith("+");
    if (plus || tz.startsWith("-")) {
      sign = tz.startsWith("+") ? 1 : -1;
      offsetStr = offsetStr.slice(1);
    }

    const [h, m, s] = offsetStr.split(":");
    const offset = Result.unwrap(TimeUnit.fromHmsStr(h, m, s, sign));

    return new FixedTimezone(tz, {
      offset,
    });
  }
}

class UtcTimezone implements LogicalTimezone<"utc"> {
  id: "utc" = "utc";
  info = {
    tzname: "UTC",
    offset: TimeUnit.ZERO,
  };
  rfc3339: string = "Z";

  now(): DateTime<Utc> {
    return DateTime.fromMse(Epoch.currentMse(), Utc);
  }

  parse(s: string): DateTime<Utc> {
    return Result.unwrap(DateTime.fromRfc3339(s)).toUtc();
  }
}

export const Utc = new UtcTimezone();
export type Utc = "utc";

export class FixedTimezone<Id extends string = FixedOffset>
  implements LogicalTimezone<Id>
{
  id: Id;
  info: TimezoneInfo;

  constructor(id: Id, info: TimezoneInfo) {
    this.id = id; // tzabbr
    this.info = info;
  }

  get rfc3339(): string {
    return this.info.offset.asSignedHm();
  }
}

export class LocalTimezone implements LogicalTimezone<Local> {
  id: Local = "local";
  info: TimezoneInfo = TimezoneInfo.local();

  now(): DateTime<Local> {
    return DateTime.fromMse(Epoch.currentMse(), Local);
  }

  get today(): NaiveDate {
    return Local.now().withTime().date;
  }

  get rfc3339(): string {
    return this.info.offset.asSignedHm();
  }

  fromMse(mse: MsSinceEpoch): DateTime<Local> {
    return DateTime.fromMse(mse, Local);
  }
}

export const Local = new LocalTimezone();
export type Local = "local";

export type FixedOffset = string & Exclude<string, Local | Utc>;
